import { nextTick } from 'vue';
import { createI18n, PostTranslationHandler, VueMessageType } from 'vue-i18n';

import { state } from '@src/composables/partnerComposable';
import localazyMetadata from '@src/languages';
import enGB from '@src/locales/en-GB.json';
import numberFormats from '@src/locales/numberFormats';
import { VUE_APP_LANGUAGE } from '@src/types/Constants';

const { baseLocale, languages } = localazyMetadata;
const DEFAULT_LOCALE = baseLocale;
const SUPPORTED_LOCALES = languages.map((language) => `${language.language}-${language.region}`);

const nonBreakingSpace: PostTranslationHandler<VueMessageType> = (message) => {
  if (typeof message === 'string') {
    return message.replace(/ ([?!:;»])/gm, '\xa0$1').replace(/(«) /gm, '$1\xa0');
  }
  return message;
};

const i18IsLocaleSupported = (locale: string): boolean => SUPPORTED_LOCALES.includes(locale);

const i18n = createI18n({
  fallbackLocale: baseLocale || 'en-GB',
  globalInjection: true,
  legacy: false,
  locale: baseLocale || 'en-GB',
  messages: {
    [baseLocale]: enGB,
  },
  numberFormats,
  postTranslation: nonBreakingSpace,
  warnHtmlMessage: false,
});

const loadLocaleMessages = async (locale: string): Promise<void> => {
  const messages = await import(/* webpackChunkName: "locale-[request]" */ `@src/locales/${locale}.json`);

  i18n.global.setLocaleMessage(locale, messages.default);

  return nextTick();
};

const i18nChangeLocale = (locale: string) => {
  const html = document.querySelector('html') as HTMLElement;

  localStorage.setItem(VUE_APP_LANGUAGE, locale);
  html.setAttribute('lang', locale.split('-')[0]);
  i18n.global.locale.value = locale;
};

const i18nCurrentLocale = () => i18n.global.locale.value;

const mergePartnerMessages = async (): Promise<void> => {
  const { partnerSlug } = state.partner;

  if (partnerSlug) {
    try {
      const messages = await import(
        /* webpackChunkName: "localePartner-[request]" */ `@src/locales/partners/${partnerSlug}/${i18n.global.locale.value}.json`
      );
      i18n.global.mergeLocaleMessage(i18n.global.locale.value, messages.default);
    } catch (error) {
      throw new Error(`Missing translation file for "${partnerSlug}" in "${i18n.global.locale.value}"`);
    }
  }
  return nextTick();
};

export {
  DEFAULT_LOCALE,
  i18IsLocaleSupported,
  i18n,
  i18nChangeLocale,
  i18nCurrentLocale,
  loadLocaleMessages,
  mergePartnerMessages,
  SUPPORTED_LOCALES,
};
