
import { defineAsyncComponent, defineComponent, shallowRef, watch } from 'vue';
import { useRoute } from 'vue-router';

import AppDefaultLayout from '@src/layouts/AppDefaultLayout.vue';

export default defineComponent({
  name: 'AppLayout',
  components: { AppDefaultLayout },
  setup() {
    const route = useRoute();
    const appLayout = shallowRef();

    watch(
      () => route.meta,
      async (meta) => {
        appLayout.value = meta?.layout
          ? defineAsyncComponent(
              () => import(/* webpackChunkName: "[request]-layout" */ `@src/layouts/${meta.layout}.vue`),
            )
          : 'AppDefaultLayout';
      },
    );

    return { appLayout };
  },
});
