
import { useHead } from '@vueuse/head';
import { camelCase } from 'lodash-es';
import { computed, defineComponent, inject } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

import { $globalState } from '@src/types/symbols';

export default defineComponent({
  name: 'App',
  setup() {
    const globalState = inject($globalState);
    const { t } = useI18n();
    const route = useRoute();

    useHead({
      htmlAttrs: {
        class: computed(() => (globalState ? `theme-${globalState.state.partner.partnerSlug}` : '')),
      },
      title: computed(() => {
        const { params, meta } = route;
        let title = 'Koala - legals docs';

        if (params?.documentType) {
          title = t('overwrite.metaTitle', {
            pageName: t(`${meta.title}.${camelCase(params.documentType as string)}.metaTitle`),
          });
        } else if (meta?.title) {
          title = t('overwrite.metaTitle', { pageName: t(meta.title) });
        }
        return title;
      }),
    });
  },
});
