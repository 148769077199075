import { reactive } from 'vue';

import { accessMatrix } from '@src/config';
import { PartnerWebConfig } from '@src/types/Partners';

const initializePartner = (): PartnerWebConfig => ({
  favicon: {
    active: false,
  },
  languages: [],
  logoFileName: '',
  partnerId: '',
  partnerName: '',
  partnerSlug: '',
  pricingTable: {
    active: false,
    footer: false,
    header: false,
  },
  productsSlugs: [],
});

const state = reactive({
  partner: initializePartner(),
});

const setPartner = (partner: string): void => {
  state.partner = accessMatrix[partner];
};

const clearPartner = (): void => {
  state.partner = initializePartner();
};

export { clearPartner, setPartner, state };
