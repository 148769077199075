import ProductsDocuments from '@src/config/productsDocuments.json';
import { AccessMatrix, PartnerConfig, ProductRoutePaths, ProductsDocumentsMatrix } from '@src/types/Partners';

const configs =
  process.env.NODE_ENV === 'production'
    ? require.context('@src/config/partners', false, /[^dummy]\.json$/)
    : require.context('@src/config/partners', false, /\.json$/);

const productsDocumentsMatrix: ProductsDocumentsMatrix = ProductsDocuments;
const partnersIds: Array<PartnerConfig['partnerId']> = [];
const accessMatrix: AccessMatrix = {};
const productRoutePaths: ProductRoutePaths = {
  comprehensive: [],
  fdc: [],
  flex: [],
  jdc: [],
};

const generateProductRoutePaths = ({ productsSlugs }: PartnerConfig): void => {
  productsSlugs.forEach(({ availableProducts, path }) => {
    const dedupeAlias = Array.from(new Set([...productRoutePaths[path], ...availableProducts]));
    productRoutePaths[path] = dedupeAlias;
  });
};

const generateConfig = () => {
  configs.keys().forEach((filename): void => {
    const partnerData: PartnerConfig = configs(filename).default || configs(filename);
    accessMatrix[`${partnerData.partnerId}`] = { ...partnerData, logoFileName: partnerData.logo.fileName };
    partnersIds.push(`${partnerData.partnerId}`);
    generateProductRoutePaths(partnerData);
  });
};

generateConfig();

export { accessMatrix, partnersIds, productRoutePaths, productsDocumentsMatrix };
