export type LocalazyLanguage = {
  language: string;
  region: string;
  script: string;
  isRtl: boolean;
  localizedName: string;
  name: string;
  pluralType: (n: number) => 'zero' | 'one' | 'two' | 'many' | 'few' | 'other';
};

export type LocalazyMetadata = {
  projectUrl: string;
  baseLocale: string;
  languages: LocalazyLanguage[];
};

const localazyMetadata: LocalazyMetadata = {
  projectUrl: 'https://localazy.com/p/legals-docs',
  baseLocale: 'en-GB',
  languages: [
    {
      language: 'en',
      region: 'GB',
      script: '',
      isRtl: false,
      name: 'British English',
      localizedName: 'British English',
      pluralType: (n) => {
        return n === 1 ? 'one' : 'other';
      },
    },
    {
      language: 'fr',
      region: 'FR',
      script: '',
      isRtl: false,
      name: 'French (France)',
      localizedName: 'Français (France)',
      pluralType: (n) => {
        return n === 0 || n === 1 ? 'one' : 'other';
      },
    },
    {
      language: 'cs',
      region: 'CZ',
      script: '',
      isRtl: false,
      name: 'Czech (Czechia)',
      localizedName: 'Čeština (Česko)',
      pluralType: (n) => {
        return n === 1 ? 'one' : n >= 2 && n <= 4 ? 'few' : 'other';
      },
    },
    {
      language: 'de',
      region: 'DE',
      script: '',
      isRtl: false,
      name: 'German (Germany)',
      localizedName: 'Deutsch (Deutschland)',
      pluralType: (n) => {
        return n === 1 ? 'one' : 'other';
      },
    },
    {
      language: 'es',
      region: 'ES',
      script: '',
      isRtl: false,
      name: 'European Spanish',
      localizedName: 'Español De España',
      pluralType: (n) => {
        return n === 1 ? 'one' : 'other';
      },
    },
    {
      language: 'it',
      region: 'IT',
      script: '',
      isRtl: false,
      name: 'Italian (Italy)',
      localizedName: 'Italiano (Italia)',
      pluralType: (n) => {
        return n === 1 ? 'one' : 'other';
      },
    },
    {
      language: 'nl',
      region: 'NL',
      script: '',
      isRtl: false,
      name: 'Dutch (Netherlands)',
      localizedName: 'Nederlands (Nederland)',
      pluralType: (n) => {
        return n === 1 ? 'one' : 'other';
      },
    },
    {
      language: 'pl',
      region: 'PL',
      script: '',
      isRtl: false,
      name: 'Polish (Poland)',
      localizedName: 'Polski (Polska)',
      pluralType: (n) => {
        return n === 1 ? 'one' : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 12 || n % 100 > 14) ? 'few' : 'many';
      },
    },
    {
      language: 'sk',
      region: 'SK',
      script: '',
      isRtl: false,
      name: 'Slovak (Slovakia)',
      localizedName: 'Slovenčina (Slovensko)',
      pluralType: (n) => {
        return n === 1 ? 'one' : n >= 2 && n <= 4 ? 'few' : 'other';
      },
    },
    {
      language: 'sv',
      region: 'SE',
      script: '',
      isRtl: false,
      name: 'Swedish (Sweden)',
      localizedName: 'Svenska (Sverige)',
      pluralType: (n) => {
        return n === 1 ? 'one' : 'other';
      },
    },
  ],
};

export default localazyMetadata;
