const numberFormats = {
  'de-DE': {
    currency: {
      currency: 'EUR',
      maximumFractionDigits: 0,
      style: 'currency',
    },
  },
  'en-GB': {
    currency: {
      currency: 'EUR',
      maximumFractionDigits: 0,
      style: 'currency',
    },
  },
  'es-ES': {
    currency: {
      currency: 'EUR',
      maximumFractionDigits: 0,
      style: 'currency',
    },
  },
  'fr-FR': {
    currency: {
      currency: 'EUR',
      maximumFractionDigits: 0,
      style: 'currency',
    },
  },
};

export default numberFormats;
