
import { defineAsyncComponent, defineComponent } from 'vue';

export default defineComponent({
  name: 'AppDefaultLayout',
  components: {
    AppLayoutFooter: defineAsyncComponent(
      () => import(/* webpackChunkName: "app-layout-footer" */ '@src/layouts/AppLayoutDefaultFooter.vue'),
    ),
    AppLayoutHeader: defineAsyncComponent(
      () => import(/* webpackChunkName: "app-layout-header" */ '@src/layouts/AppLayoutDefaultHeader.vue'),
    ),
  },
});
