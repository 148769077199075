import { breakpointsTailwind, useBreakpoints } from '@vueuse/core';
import { createHead } from '@vueuse/head';
import { createApp } from 'vue';

import App from '@src/App.vue';
import { clearPartner, setPartner, state } from '@src/composables/partnerComposable';
import { i18n } from '@src/i18n';
import AppLayout from '@src/layouts/AppLayout.vue';
import router from '@src/router';
import { $breakpoints, $globalState } from '@src/types/symbols';

import 'front-components/src/styles/index.scss';
import '@src/styles/Theme.scss';

const breakpoints = useBreakpoints(breakpointsTailwind);
const appLegalsDocs = createApp(App);

appLegalsDocs.provide($breakpoints, breakpoints);
appLegalsDocs.provide($globalState, { clearPartner, setPartner, state });
appLegalsDocs.component('AppLayout', AppLayout);
appLegalsDocs.use(i18n);
appLegalsDocs.use(createHead());
appLegalsDocs.use(router);
appLegalsDocs.mount('#app-legals-docs');
