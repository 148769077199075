import { upperFirst } from 'lodash-es';
import { h, resolveComponent } from 'vue';
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

import { productRoutePaths } from '@src/config';
import { i18nCurrentLocale, mergePartnerMessages } from '@src/i18n';
import {
  validateLanguage,
  validatePartnerId,
  validatePartnerLanguage,
  validatePartnerPricingTable,
  validatePartnerProductSlug,
} from '@src/middlewares';
import { ProductType } from '@src/types/Partners';

const generateDynamicRoutes = () => {
  const entries = Object.entries(productRoutePaths);

  return entries.reduce((routes, [productSlug, partnerProducts]) => {
    const productNameCapitalize = upperFirst(productSlug);

    const partnerRoutes = partnerProducts.map((product) => {
      const productCapitalize = upperFirst(product);
      return {
        beforeEnter: [validatePartnerId, validatePartnerLanguage, validatePartnerProductSlug],
        component: () => import(/* webpackChunkName: "[request]" */ `@src/views/${productNameCapitalize}View.vue`),
        meta: { productName: product, productSlug: productSlug as ProductType, title: product },
        name: productCapitalize,
        path: `${product}/:documentType`,
      };
    });

    return [...routes, ...partnerRoutes];
  }, new Array<RouteRecordRaw>());
};

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: `/${i18nCurrentLocale()}/`,
  },
  {
    beforeEnter: (to, from, next) => {
      return !to.name ? next({ name: 'NotFound', params: { ...to.params } }) : next();
    },
    children: [
      ...generateDynamicRoutes(),
      {
        beforeEnter: [validatePartnerId, validatePartnerLanguage, validatePartnerPricingTable],
        component: () => import(/* webpackChunkName: "pricing-table" */ '@src/views/PricingTableView.vue'),
        meta: { layout: 'AppFullWidthLayout', title: 'pricingTable.metaTitle' },
        name: 'PricingTable',
        path: 'pricing-table',
      },
      {
        component: () => import(/* webpackChunkName: "unauthorized" */ '@src/views/UnauthorizedView.vue'),
        meta: { title: 'unauthorized' },
        name: 'Unauthorized',
        path: '403',
      },
      {
        component: () => import(/* webpackChunkName: "notFound" */ '@src/views/NotFoundView.vue'),
        meta: { title: 'notFound' },
        name: 'NotFound',
        path: ':pathMatch(.*)*',
      },
    ],
    component: {
      render() {
        return h(resolveComponent('router-view'));
      },
    },
    path: '/:language',
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: { name: 'NotFound', params: { language: i18nCurrentLocale() } },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  await validateLanguage(to, from, next);
});

router.beforeResolve(async (to, from, next) => {
  await mergePartnerMessages();
  return next();
});

export default router;
