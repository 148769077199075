import { usePreferredLanguages } from '@vueuse/core';
import { NavigationGuard, START_LOCATION } from 'vue-router';

import {
  DEFAULT_LOCALE,
  i18IsLocaleSupported,
  i18n,
  i18nChangeLocale,
  loadLocaleMessages,
  SUPPORTED_LOCALES,
} from '@src/i18n';
import { VUE_APP_LANGUAGE } from '@src/types/Constants';

let firstNavigation = true;
const validateLanguage: NavigationGuard = async (to, from, next) => {
  const { language } = to.params;
  const { language: previousLanguage } = from.params;

  if (!i18IsLocaleSupported(language)) {
    return next({ path: `/${DEFAULT_LOCALE}` });
  }

  if (from === START_LOCATION && firstNavigation) {
    const customerLanguages = usePreferredLanguages();
    const sharedLanguages = customerLanguages.value.filter((locale) => SUPPORTED_LOCALES.includes(locale));
    const savedLang = localStorage.getItem(VUE_APP_LANGUAGE);
    firstNavigation = false;

    if ((savedLang && savedLang !== 'en-GB') || (sharedLanguages.length > 0 && sharedLanguages[0] !== 'en-GB')) {
      const newLanguage = savedLang || sharedLanguages[0];
      return next({ ...to, params: { ...to.params, language: newLanguage } });
    }
  }

  if (!i18n.global.availableLocales.includes(language)) {
    await loadLocaleMessages(language);
  }

  if (previousLanguage !== language) i18nChangeLocale(language);

  return next();
};

export default validateLanguage;
