import { NavigationGuard } from 'vue-router';

import { clearPartner, setPartner, state } from '@src/composables/partnerComposable';
import { partnersIds, productsDocumentsMatrix } from '@src/config';

const validatePartnerId: NavigationGuard = async (to, from, next) => {
  const partnerId = to.query?.partner;

  if (typeof partnerId !== 'string' || !partnersIds.includes(partnerId)) {
    clearPartner();
    return next({ name: 'Unauthorized', params: { ...to.params } });
  }

  setPartner(partnerId);
  return next();
};

const validatePartnerLanguage: NavigationGuard = async (to, from, next) => {
  const language = to.params?.language as string;
  const { languages } = state.partner;

  if (!language || !languages.includes(language)) {
    return next({ name: 'Unauthorized', params: { ...to.params, language: 'en-GB' } });
  }

  return next();
};

const validatePartnerProductSlug: NavigationGuard = async (to, from, next) => {
  const { productName = '', productSlug } = to.meta;
  const { documentType } = to.params;
  const { productsSlugs } = state.partner;

  const partnerHasProduct = productsSlugs.some(
    ({ availableProducts, path }) => availableProducts.includes(productName) && path === productSlug,
  );

  if (
    !productSlug ||
    !partnerHasProduct ||
    !productsDocumentsMatrix[productSlug] ||
    !productsDocumentsMatrix[productSlug].includes(documentType)
  ) {
    return next({ name: 'NotFound', params: { ...to.params } });
  }

  return next();
};

const validatePartnerPricingTable: NavigationGuard = async (to, from, next) => {
  const { pricingTable } = state.partner;

  if (!pricingTable.active) {
    return next({ name: 'NotFound', params: { ...to.params } });
  }

  return next();
};

export { validatePartnerId, validatePartnerLanguage, validatePartnerPricingTable, validatePartnerProductSlug };
